
/* ______________ Mobile __________________ */

@media screen and (max-width: 833px) {

}

/* ______________ Destkop __________________ */

footer {
    display: flex;
    overflow: hidden;
    height: 10vh;
    margin: 0;
    padding: var(--default-padding);    
    background-color:var(--default-background);
    border-top: 1px solid var(--navbar-border-color); 
}
