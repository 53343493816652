
/* ______________ Mobile __________________ */

@media screen and (max-width: 833px) {

}

/* ______________ Destkop __________________ */

.app-navbar {
    display: flex;
    overflow: hidden;
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: var(--navbar-height);
    margin: 0;
    padding: var(--default-padding);
    background-color: var(--nav-bar);
    color: var(--default-text);
    backdrop-filter: blur(5px);
    border-bottom: 1px solid var(--navbar-border-color);
}

.app-navbar button {
    width: auto;
    height: 1em;
	padding: 0;
    background-color: transparent;
    color: var(--default-header);
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}