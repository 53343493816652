/* DESCRIPTION : constants for the default mode */
:root {
  --default-white: rgb(236, 236, 236);
  --html-background: rgb(25, 25, 25);
   
  --default-content-background: rgba(139, 139, 139, 0.5);
  --default-foreground: rgb(250, 250, 250);
  --default-background: rgb(232, 232, 232);
  --default-header: rgb(25, 25, 25);
  --default-text: rgb(40, 40, 40);
  --default-link: rgb(0, 123, 255);
  --nav-bar: rgba(240, 240, 240, 0.859);
  --navbar-height: 2rem;
  --navbar-border-color: rgba(0, 0, 0, 0.05);
  --default-padding: 1em;
}

/* DESCRIPTION : constants for the dark mode */
[data-theme="dark"] {
  --default-content-background: rgba(127, 127, 127, 0.5);
  --default-foreground: rgb(31, 31, 31);
  --default-background: rgb(25, 25, 25);
  --nav-bar: rgba(25, 25, 25, 0.859);
  --navbar-border-color: rgba(255, 255, 255, 0.05);
  --default-header: rgb(235, 235, 235);
  --default-text: rgb(200, 200, 200);
}
