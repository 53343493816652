
/* ______________ Mobile __________________ */

@media screen and (max-width: 833px) {

}

/* ______________ Destkop __________________ */

* {
  margin: 0;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

html {
  background-color: var(--html-background);
  text-decoration: none;
}

body {
  margin: 0;
}

h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

footer {
  margin: 0;
}

link {
  text-decoration: none;
}

.App {
  background: var(--default-background);
  color: var(--default-text);
}