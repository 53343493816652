
/* ______________ Mobile __________________ */

@media only screen and (min-width: 834px) { 

    .main-container {
        overflow: hidden;
        width: 60% !important;
        min-width: 60% !important;
        padding-right: 20% !important;
        padding-left: 20% !important;
        justify-content: center;
        align-items: center;
        margin:0;
        
    }

    .projects-container {
        grid-template-columns: repeat(5, 1fr) !important;
    }

    .profile-picture {
        width: 10em !important;
        height: 10em !important;
    }    
} 

/* ______________ Destkop __________________ */

.space-1em {
    height: 1em;
    width: 100%;
    background-color: none;
}

.main-container {
    overflow: hidden;
    width: 94%;
    min-width: 94%;
    height: 100%;
    min-height: 100vh;
    padding-top: calc(var(--navbar-height) + 3em);
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: var(--default-padding);
    margin: 0 auto;
    background-color: var(--default-foreground);
}

.main-container h1, h2, h3 {
    color: var(--default-header);
}

.about-container {
    overflow: hidden;
    background-color: none;
}

.profile-picture {
    width: 8em;
    height: 8em;
    border-radius: 50%;
    margin-right: 1em;
}

.profile-header-container {
    display: flex;
    align-items: center;
}

.profile-header-container h1{
    font-size: 2.5em;
}

.social-links-container {
    display: flex;
    overflow: hidden;
    list-style-type: none;
    background-color: none;
    font-size: 0.95em;
}

.social-links-container li {
    margin-right: 1em;
}

.social-links-container .link {
    color: var(--default-link);
    text-decoration: none;
}

.stats-container {
    display: flex;
    overflow: hidden;
    list-style-type: none;
    background-color: none;
    font-size: 0.95em;
}

.projects-container {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(3, 1fr);
    height: auto;
    min-height: 50vh;
    width: 100%;
}

.project-item {
    overflow: hidden;
    height: 100%;
    background-color: var(--default-content-background);
    cursor: pointer;
} 


